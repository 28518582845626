import React from "react";
import "./SocialMedia.css";
import {socialMediaLinks} from '../../../../portfolio';

export default function socialMedia() {
    return (
        <div className="social-media-div">
            <a href={socialMediaLinks.linkedin} className="icon-button linkedin"  rel="noreferrer" target="_blank">
                <i className="fab fa-linkedin-in"/>
                <span/>
            </a>
            <a href={socialMediaLinks.medium} className="icon-button medium"  rel="noreferrer" target="_blank">
                <i className="fab fa-medium"/>
                <span/>
            </a>
            <a href={`mailto:${socialMediaLinks.email}`} className="icon-button google"  rel="noreferrer" target="_blank">
                <i className="fab fa-google"/>
                <span/>
            </a>
            <a href={socialMediaLinks.github} className="icon-button github"  rel="noreferrer" target="_blank">
                <i className="fab fa-github"/>
                <span/>
            </a>
        </div>
    );
}
