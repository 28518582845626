import React, {Component, createRef} from "react";
import "./EducationCard.css";

export default class EducationCard extends Component {
    constructor(props) {
        super(props);
        this.imgRef = createRef();
    }

    GetDescBullets(props) {
        let out = null
        if (props.descBullets) {
            out = props.descBullets.map((item) => <li className="subTitle" key={props.school.schoolName}>{item}</li>)
        }
        return out;
    }

    render() {
        return (
            <div>
                <div className="education-card">
                    <div className="education-card-left">
                        <img
                            crossOrigin={"anonymous"}
                            ref={this.imgRef}
                            className="education-roundedimg"
                            src={this.props.school.logo}
                            alt={this.props.school.schoolName}
                        />
                    </div>
                    <div className="education-card-right">
                        <h5 className="education-text-school">{this.props.school.schoolName}</h5>

                        <div className="education-text-details">
                            <h5 className="education-text-subHeader">{this.props.school.subHeader}</h5>
                            <p className="education-text-duration">{this.props.school.duration}</p>
                            <p className="education-text-desc">{this.props.school.desc}</p>
                            <div className="education-text-bullets">
                                <ul>
                                    <this.GetDescBullets descBullets={this.props.school.descBullets}/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="education-card-border"></div>
            </div>
        );
    }
}
