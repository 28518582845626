import React, {Component} from "react";
import "./Experience.css";
import ExperienceCard from "../../components/ExperienceCard/ExperienceCard";
import {workExperiences} from '../../../../portfolio';
import SectionContainer from "../../../../shared_components/SectionContainer/SectionContainer";

export default class Experience extends Component {
    render() {
        return (
            <SectionContainer id={"experience"} title={"Experience"}>
                <div className="experience-card-container">
                    {workExperiences.experience.map((card, index) => {
                        return (
                            <ExperienceCard key={index} cardInfo={card}/>
                        );
                    })}
                </div>
            </SectionContainer>
        );
    }
}
