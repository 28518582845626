import Header from "./sections/Header/Header";
import Welcome from "./sections/Welcome/Welcome";
import Skills from "./sections/Skills/Skills";
import Education from "./sections/Education/Education";
import Experience from "./sections/Experience/Experience";
import CaseStudies from "./sections/CaseStudies/CaseStudies";
import About from "./sections/About/About";
import React, {Component} from "react";

export class HomePage extends Component {


    render() {
        return (
            <div>
                <Header/>
                <Welcome/>
                <Skills/>
                <Experience/>
                <CaseStudies/>
                <Education/>
                {/*<About/>*/}
            </div>
        );
    }
}