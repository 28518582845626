import React, {Component} from "react";
import "./About.css"
import SectionContainer from "../../../../shared_components/SectionContainer/SectionContainer";
import {aboutMe} from '../../../../portfolio'
import MyFace from "../../components/myFace/myFace";

export default class About extends Component {


    render() {
        return (
            <SectionContainer title={"About"} id={"about"}>
                <div className={"about-flex-container"}>
                    <div className={"about-flex-item"}>{aboutMe.text}</div>
                    <div className={"about-flex-item"}><MyFace/></div>
                </div>
            </SectionContainer>
        );
    }
}
