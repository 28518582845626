import React from "react";
import "./Welcome.css";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import Button from "../../components/Button/Button";
import {greeting} from '../../../../portfolio';
import GreetingPic from './programmer.svg'


export default function Welcome() {
    return (
        <div className="greet-main" id="greeting">
            <div className="greeting-main">
                <div className="greeting-text-div">
                    <div>
                        <h1 className={"greeting-text"}>{" "}{greeting.title}{" "}</h1>
                        <p className={"greeting-text-p subTitle"}>{greeting.subTitle}</p>
                        <SocialMedia/>
                        <div className="button-greeting-div">
                            <Button
                                text="Contact"
                                href="https://www.linkedin.com/in/ben-shearlaw"
                                newTab={true}
                            />
                            <Button
                                text="View CV"
                                newTab={true}
                                href={greeting.resumeLink}
                            />
                        </div>
                    </div>
                </div>
                <div className="greeting-image-div">
                    <img src={GreetingPic} alt={"greeting"}/>
                </div>
            </div>
        </div>
    );
}
