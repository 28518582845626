import React, {Component} from "react";
import "./Skills.css"
import SoftwareIcons from '../../../../shared_components/SoftwareIcons/SoftwareIcons'
import {skillsSection, homeScreenIcons} from '../../../../portfolio';
import SectionContainer from "../../../../shared_components/SectionContainer/SectionContainer";

export default class Skills extends Component {


    SkillsText() {
        return (
            <div className="skills-text-div">
                {skillsSection.skills.map((skills, index) => {
                    return (
                        <p className="subTitle skills-text" key={index}>{skills}</p>
                    );
                })}
            </div>
        )

    }

    render() {
        return (
            <SectionContainer id={"skills"} title={"Skills"}>
                <div className={"skills-flex-container"}>
                    <div className={"skills-flex-item"}><SoftwareIcons icons={homeScreenIcons}/></div>
                    <div className={"skills-flex-item"}>
                        <this.SkillsText/>
                    </div>
                </div>
            </SectionContainer>
        );
    }
}
