import React, {Component} from "react";
import {navBar} from "portfolio";
import Headroom from "react-headroom";
import "./Header.css"
import "./dropdown.css"


export default class Header extends Component {


    render() {
        return (
            <Headroom>
                <header className="topnav" id={"myTopnav"}>
                    <a href={"/"}><b className={"logo"}>{navBar.logo}</b></a>
                </header>
            </Headroom>
        );
    }
}
