import React from "react";
import "./CaseStudyCard.css";

export default function CaseStudyCard({cardInfo}) {
    return (
        <div className={"caseStudyCard_main"}>
            <a className={"anchor"} href={"case_studies/" + cardInfo.sub_url}>
                <div className={"caseStudyCard_illustration"} style={{background: cardInfo.colour}}>
                    <img className={"caseStudyCard_image"} src={cardInfo.image} alt={"case study"}/>
                    <ul className={"caseStudyCard_tags_list"}>
                        {cardInfo.tags.map((tag, index) => (
                            <li className={"caseStudyCard_tag"} key={index}>{tag}</li>
                        ))}
                    </ul>
                </div>
                <div className={"caseStudyCard_desc"}>{cardInfo.desc}</div>
            </a>
        </div>
    )
        ;
}
