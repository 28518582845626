const data = {
    "property_api": {
        "banner_title": "Outra Property API",
        "banner_desc": "A B2B API which returns data on UK residential property. A range of businesses integrate this API into their services to improve their advertising efforts.",
        "colour": "#842844",
        "image": "case_studies/monitor.png",
        "main_text": [
            {
                "title": "Background",
                "content": "This API was designed for other pre-authorised businesses to use in order to fetch data on residential properties. Sophisticated queries could be constructed using a range of query parameters, which allow filtering, ordering, limiting and skipping of property records. properties could be filtered using either simple equivalence or more complex equivalence such as regex pattern matching."
            },
            {
                "title": "API Features",
                "content": "This API contains a range of features, services and protocols I would like to mention such as:<br><br>Fully async compliant - this API was implemented using asynchronous DB operations and built on top of a ASGI web server implementation, allowing for a high number of concurrent requests to be serviced in short period of time therefore optimising the utilization and performance of computing resources to achieve the highest possible efficiency and cost-effectiveness.<br><br>The OAuth2.0 protocol was used to secure the API. Signed JWT Bearer tokens were issued as a means of access. bcrypt hashing was used to store sensitive data.<br><br>The Psycopg 3 database library was used to connect to Postgres databases. Advanced features such as asynchronous operations and connection pools were implemented to keep response times short and availability high.<br><br> The API was built using FastAPI and deployed with Uvicorn.<br><br> I implemented a rate limiting feature to limit users to a given number of requests per month.<br><br>I integrated <a href=\"https://sentry.io\">sentry.io</a> tooling such as error monitoring, performance reporting and profiling.<br><br>A range of testing tools were used such as API tests, unit testing with coverage using PyTest, and load testing using <a href=\"https://locust.io\">locust.io</a>.<br><br>This application was containerised with Docker, and deployed using Kubernetes, though I was not responsible for the Kubernetes component.<br><br>I created scripts to generate reports on API usage, for example how many requests are made each month by client, and which endpoints they make use of, and so on."
            }
        ]
    },
    "data_api": {
        "banner_title": "Phrasee Serverless API Suite",
        "banner_desc": "A suite of API's carrying out necessary business functions for Phrasee's customer facing web app.",
        "colour": "#c750fa",
        "image": "case_studies/monitor.png",
        "main_text": [
            {
                "title": "What I Did",
                "content": "I delivered a range of REST API's, including a performance reporting JSON API. This API serviced Phrasee's reports and charting dashboard which detailed performance metrics of customer marketing campaigns. These endpoints allowed for complex query parameters to be specified to configure and format the response, for example the response could be formatted on a daily/weekly/monthly basis, different date ranges could be specified, different campaigns could be included/excluded, sort order could be specified, and so on. Another API hosted a ML model which responded with sentiments analysis of a given piece of marketing copy, for example it would score email subject lines on how urgent, joyful or surprising (among others) they are. Another API I developed was a semantic search service which given a string input would search and return similar string fragments."
            },
            {
                "title": "How I Did It",
                "content": "These applications were built using either Flask or FastAPI. Pandas library was used for data analysis to compute metrics such as uplift and incremental revenue gain. They were containerised using Docker, deployed using a Jenkins CI/CD pipeline onto a range of AWS offerings such as Lambda and ECS. These services made use of other AWS offerings such as SQS and S3."
            },
            {
                "title": "Challenges",
                "content": "One notable challenge was making the service performant when dealing with very large datasets. I achieved this through the use of code profiling tools such as the python pstats module, and profile viewer tools such as SnakeNiz."
            }
        ]
    },
    "atamate_design_studio": {
        "banner_title": "Atamate Design Studio",
        "banner_desc": "This B2B application enables resellers and in-house professionals to design and cost Atamate building operating systems for any building.",
        "colour": "#93d6f9",
        "image": "case_studies/monitor.png",
        "main_text": [
            {
                "title": "Objective",
                "content": "To deliver a tool which enables building professionals to quickly and accurately define a technical specification for the <a href='/case_studies/atbos'> Atamate Building Operating System (atBOS)</a> and pricing approximations for any building.\n"
            },
            {
                "title": "How I did it",
                "content": "<h2>Technology Stack</h2> \n\n<h3>Back-end</h3>\n\n<p>ADS’s back-end is built using Django. Django was a natural choice for the project as I wanted to use many of the tools it offers out of the box such as the Django admin site and the ORM. Also with the application being so large, the rigid project structure was found to be beneficial as it helped contributors navigate the codebase. </p>\n\n<h3>Front-end</h3>\n\n<p>ADS’s front-end is built using vanilla HTML (and the Django templating language) in conjunction with vanilla JavaScript and CSS.</p>\n\n\n<h3>Deployment</h3>\n<p>ADS is deployed with an AWS EC2 Ubuntu instance running Nginx, Gunicorn, Certbot and more associated web server technologies. </p></p>\n"
            },
            {
                "title": "Challenges",
                "content": "One notable challenge was accommodating for the vast range of new compatible hardware coming to market over time. Quickly it became apparent the development team needed a datastore which enabled us to rapidly model and easily maintain a software specification of a given compatible device. I added an a NoSQL datastore which allowed us to benefit from having a more dynamic schema, which was important as different devices had differing fields and data points about them which other devices did not. In the prior relational database, tackling migrations and table alterations on a frequent basis was holding the develop pace up, though moving to a JSON document object store many of these issues were relived."
            }
        ]
    },
    "atbos": {
        "banner_title": "Atamate Building Operating System",
        "banner_desc": "The Atamate Building Operating System (atBOS) can be deployed in any building type to reduce energy consumption, reduce maintenance and management costs whilst improving the comfort, security and safety of occupants.",
        "colour": "#F9A826",
        "image": "case_studies/monitor3.png",
        "main_text": [
            {
                "title": "Objective",
                "content": "To deliver a robust IoT and Bluetooth compliant shared platform."
            },
            {
                "title": "How I did it",
                "content": "<div>\n<p>\n    At the heart of an atBOS installation, a linux server - the atBOS hub, ran which served a number of purposes, including receiving data from sensors around the installation, sending control instructions to actuators, running control and analysis software and much more. \n</p>\n\n<p>\n    \n</p>\n\n<p>\n<h2>Redis</h2>\nRedis enabled AtBOS to read/write a large amount of sensor data in near real-time.\n</p>\n\n<p>\n<h2>WebSockets</h2>\nWebSockets enabled atBOS to have real time, bidirectional communication between a client's browser and the atBOS hub, which was an important feature given the nature of the system. For example, if a light was turned on in a room, it was a requirement for the UI to show a room as illuminated seamlessly without a need for a refresh.</p>\n\n<p>\n<h2>Pub-Sub</h2>\nPub-Sub enabled Atamate to build event-driven architecture,which allowed for immediate responsiveness to events or changes in the environment. When a sensor detects a change, such as a temperature fluctuation or motion detection, it triggers an event. The event-driven architecture ensures that the system can react promptly to these events and take appropriate actions based on the incoming data.\n</p>\n\n<p></div>"
            }
        ]
    },
    "sketchup_ruby_plugin": {
        "banner_title": "Atamate SketchUp Ruby Plugin",
        "banner_desc": "This B2B application enables resellers and in-house professionals to carry-out visually orientated 3D designs for Atamate building systems.",
        "colour": "#89fa50",
        "image": "/case_studies/monitor2.png",
        "main_text": [
            {
                "title": "Objective",
                "content": "To deliver a Sketchup plugin which enables professionals to quickly and accurately describe a technical specification for a building automation system, which integrates seamlessly with the <a href='/case_studies/atamate_design_studio'> Atamate Design Studio</a>.\n"
            },
            {
                "title": "How I did it",
                "content": "<h2>Approach</h2> A lose iterative agile approach was taken. This was a solo project, therefore I was responsible also for QA and deployment.<h2>Technology Stack</h2> \n\n<p>This tool is built using SketchUp's Ruby API which allows developers to extend SketchUp, a 3D modeling computer program, to do more than it does out of the box. \n\n<p>The application also heavily relies on the usage of a RESTful API served by the atamate design studio application (ADS). As these applications are part of the same suite of design tools, communication between the ADS server and the client was necessary to maintain a synchronized dataset for a given installation. The SketchUp Ruby extension makes a range of HTTP GET and POST requests in order to retrieve installation data, save installation data, perform backups and more. Bearer Token based Authentication was implemented using the OAuth2.0 protocol.</p>\n\n\n<h2>Testing</h2> \n\n<p>Both unit and functional testing was carried out using TestUp 2, which is a wrapper built on top of the minitest testing facilities for the Ruby programming language. </p>\n\n"
            },
            {
                "title": "Challenges",
                "content": "One notable challenge this project imposed was the limitations of the original technology stack the design tool started its life with. Prior to when I started developing this tool, the foundation of the application was based on the html5 canvas element. Over time as the business requirements grew, it became more apparent it was in the businesses best interest to switch technology stacks to work from a more feature rich and flexible foundation."
            }
        ]
    }
}
export {
    data
};