import React, {Component} from "react";
import "./SoftwareIcons.css"
import {icons_dir} from "../../portfolio";

export default class SoftwareIcons extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    Icon(props) {
        let spec = icons_dir[props.icon];
        if (spec.fontAwesomeClassname) {
            return (
                <li className="software-skill-inline" name={spec.displayName} key={spec.displayName}>
                    <i className={spec.fontAwesomeClassname}></i>
                    <p>{spec.displayName}</p>
                </li>
            )
        } else {
            return (
                <li className="software-skill-inline" name={spec.displayName} key={spec.displayName}>
                    <img src={spec.src} className={"custom-icon"} alt={"skill"}/>
                    <p>{spec.displayName}</p>
                </li>
            )
        }

    }

    render() {
        return (
            <div>
                <div className="software-skills-main-div">
                    <ul className="dev-icons">
                        {this.props.icons.map((icon, index) => {
                            return (<this.Icon icon={icon} key={icon}/>);
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}
