import React, {Component} from "react";
import "./Banner.css"

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.props = props
  }


  render() {
    return (
        <div className={"outer"}>
          <div className="flex-container" style={{background: this.props.colour}}>
            <div className={"flex-item"}>
              <h1 className={"title_text"}>{this.props.title}</h1>
              <div className="desc">{this.props.desc}</div>
            </div>
            <div className="flex-item">
              <img className={"banner_image"} src={"../" + this.props.image} alt={"banner"}/>
            </div>
          </div>
        </div>
    );
  }
}
