import {HomePage} from "./pages/home/HomePage";
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {CaseStudiesPage} from "./pages/case_studies/CaseStudiesPage";
import {data} from './case_studies.js'

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <main>
                    <Switch>
                        <Route exact path="/">
                            <HomePage/>
                        </Route>
                        {Object.keys(data).map(function (keyName, keyIndex) {
                            return (
                                <Route exact path={`/case_studies/${keyName}`}>
                                    <CaseStudiesPage content={data[keyName]}/>
                                </Route>
                            )
                        })}
                    </Switch>
                </main>
            </BrowserRouter>
        );
    }
}

export default App;