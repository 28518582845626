//in reverse order as floated right..
const navBar = {
    logo: "Ben Shearlaw",
    buttons: [
        {text: "Education", href: "#education"},
        {text: "Experience", href: "#experience"},
        {text: "Skills", href: "#skills"},
        {
            text: "Case Studies", href: "#caseStudies", dropdowns: [
                {text: "Outra Property API", href: "/case_studies/property_api"},
                {text: "Atamate Design Studio", href: "/case_studies/atamate_design_studio"},
                {text: "Atamate BuildingOS", href: "/case_studies/atbos"},
                {text: "Sketchup Ruby Extension", href: "/case_studies/sketchup_ruby_plugin"},
            ]
        },
    ]
}


const greeting = {
    title: "Welcome",
    subTitle: "Passionate and experienced Python Engineer specialising in Backend engineering, specifically API & web development.  Strong AWS experience. Over 7 years of commercial experience. Open to perm or contract positions.",
    resumeLink: "https://drive.google.com/file/d/1-KH3x9D7uvIcs0gSnxnAv9GQ6ixWgOKV/view?usp=sharing"
};

const skillsSection = {
    skills: [
        "⚡ Specialise in building web applications & REST APIs.",
        "⚡ Extensive experience with serverless & microservices orientated systems design.",
        "⚡ Deployment experience using a range of services such as AWS, Docker and Jenkins.",
        "⚡ Engineered a range of Database-integrated applications using databases such as PostgreSQL, MongoDB, Redis and GraphQL.",
    ]
}


const icons_dir = {
    "django": {
        displayName: "django",
        src: 'skills/django.svg'
    },
    "flask": {
        displayName: "flask",
        src: 'skills/flask.svg'
    },
    "fastApi": {
        displayName: "FastApi",
        src: 'skills/fastapi.svg'
    },
    "aws": {
        displayName: "aws",
        fontAwesomeClassname: "fab fa-aws"
    },
    "docker": {
        displayName: "docker",
        src: 'skills/docker.svg'
    },
    "jenkins": {
        displayName: "jenkins",
        src: 'skills/jenkins.svg'
    },
    "sql": {
        displayName: "sql",
        fontAwesomeClassname: "fas fa-database"
    },
    "mongodb": {
        displayName: "mongodb",
        src: 'skills/mongodb.svg'
    },
    "graphql": {
        displayName: "graphql",
        src: 'skills/graphql.svg'
    },
    "oauth": {
        displayName: "oauth",
        src: 'skills/oauth.svg'
    },
    "postman": {
        displayName: "postman",
        src: 'skills/postman.svg'
    },
    "jira": {
        displayName: "jira",
        fontAwesomeClassname: "fab fa-jira"
    },
    "git": {
        displayName: "git",
        fontAwesomeClassname: "fab fa-git-alt"
    },
    "github": {
        displayName: "github",
        fontAwesomeClassname: "fab fa-github"
    },
};


const homeScreenIcons = Object.keys(icons_dir);

const educationInfo = {
    schools: [
        {
            schoolName: "Oxford Brookes",
            logo: 'education/brookes_logo.png',
            subHeader: "Bachelor of Science with First Class Honours in Computer Science with Business Management",
            duration: "September 2014 - December 2018",
        },
        {
            schoolName: "Exeter College",
            logo: 'education/exeterCollege.png',
            subHeader: "A-Levels in Economics, Biology and Information Technology",
            duration: "September 2012 - December 2014",
        }
    ]
}


const workExperiences = {
    experience: [
                {
            role: "Python Engineer",
            company: "Met Office",
            companylogo: 'experiences/met_office.png',
            date: "October 2023 – current",
            desc: "The United Kingdom's national weather service.",
            website: "https://www.metoffice.gov.uk/",
            descBullets: [
                "Providing weather and climate-related services to the Armed Forces, government departments, the public, civil aviation, shipping, industry, agriculture and commerce.",
            ]
        },
        {
            role: "Python Engineer",
            company: "Outra",
            companylogo: 'experiences/outraLogo.png',
            date: "June 2023 – Sep 2023",
            desc: "AI-Powered advertising solutions for the UK residential property market.",
            website: "https://outra.co.uk/",
            descBullets: [
                "Advanced Real Estate Analytics",
                "Unmatched residential property data on nearly 30M UK households.",
            ]
        },
        {
            role: "Python Engineer",
            company: "Phrasee",
            companylogo: 'experiences/phraseeLogo.png',
            date: "Apr 2021 – Dec 2022",
            desc: "AI-powered platform for enhanced customer experience",
            website: "https://www.phrasee.co",
            descBullets: [
                "AI-powered natural language generation",
                "Brand language optimization.",
            ]
        },
        {
            role: "Python Engineer",
            company: "Atamate",
            companylogo: 'experiences/atamateLogo.png',
            date: "Sep 2016 – Apr 2021",
            desc: "Developing the UK's Premier Building Operating System",
            website: "https://www.atamate.com",
            descBullets: [
                "Data driven control for all buildings",
                "Bluetooth integrated Cloud based IoT system",
            ]
        }
    ]
};
const socialMediaLinks = {
    linkedin: "https://www.linkedin.com/in/ben-shearlaw",
    github: "https://github.com/ben-shearlaw",
    medium: "https://medium.com/@benshearlaw",
    email: "benshearlaw@hotmail.co.uk",
};


const caseStudies = [
    {
        desc: "Outra Property Data API",
        image: 'case_studies/monitor2.png',
        tags: ["Python", "FastAPI", "REST API", "PostgreSQL", "Async"],
        sub_url: "property_api",
        colour: "#842844"
    },
    {
        desc: "Phrasee REST API Microservices Suite",
        image: 'case_studies/monitor2.png',
        tags: ["Python", "Flask", "FastAPI", "REST APIs", "Microservices"],
        sub_url: "data_api",
        colour: "#c750fa"
    },
    {
        desc: "Atamate Design Studio - The Web App which enables building professionals to design Atamate building control systems",
        image: 'case_studies/monitor.png',
        tags: ["Python", "Django", "JavaScript"],
        sub_url: "atamate_design_studio",
        colour: "#93d6f9"
    },
    {
        desc: "atBOS - The UK's premier Building Operating System",
        image: 'case_studies/monitor3.png',
        tags: ["Python", "WebSockets", "Redis", "Microservices", "Pub-Sub"],
        sub_url: "atbos",
        colour: "#F9A826"
    },
    {
        desc: "Atamate Visual Design Aid - A Sketchup Ruby Extension",
        image: 'case_studies/monitor2.png',
        tags: ["Ruby", "SketchUp", "CAD", "RESTful API"],
        sub_url: "sketchup_ruby_plugin",
        colour: "#89fa50"
    }
]
const aboutMe = {
    text: "I've been developing software professionally since 2016. My software engineering journey started at a university placement and I've not looked back since. Geographically my job has led me to work from across the globe including my current location, England, as well as Thailand and The Netherlands. The majority of my career has been spent as a remote worker whereby I operate from a home office or co-working spaces."
}


export {
    navBar,
    skillsSection,
    educationInfo,
    workExperiences,
    socialMediaLinks,
    greeting,
    caseStudies,
    aboutMe,
    homeScreenIcons,
    icons_dir
};
