import React from "react";
import "./myFace.css";
import myFace from './headshot_bw.jpg'

export default function MyFace({prof}) {
    return (
        <div className="image-content-profile">
            <img src={myFace} className="profile-image" alt={"headshot"}/>
        </div>
    );
}
