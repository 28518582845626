import React, {Component} from "react";
import "./Education.css"
import {educationInfo} from '../../../../portfolio';
import EducationCard from "../../components/EducationCard/EducationCard";
import SectionContainer from "../../../../shared_components/SectionContainer/SectionContainer";

export default class Education extends Component {


    render() {
        return (
            <SectionContainer id="education" title="Education">
                <div className="education-card-container">
                    {educationInfo.schools.map((school, index) => (
                        <EducationCard school={school} key={index}/>
                    ))}
                </div>
            </SectionContainer>
        );
    }
}
