import React, {Component} from "react";
import "./InnerHTMLSectionContainer.css"

export default class InnerHTMLSectionContainer extends Component {
    constructor(props) {
        super(props);
        this.props = props

    }

    createMarkup() {
        return {__html: this.props.children};
    }


    render() {
        return (
            <div className="section-container" id={this.props.id}>
                <h1 className="section-heading">{this.props.title}</h1>
                <p dangerouslySetInnerHTML={this.createMarkup()}></p>
            </div>
        );
    }
}
