import React, {createRef} from "react";
import "./ExperienceCard.css";

export default function ExperienceCard({cardInfo, isDark}) {
    const imgRef = createRef();

    const GetDescBullets = ({descBullets, isDark}) => {
        return descBullets ? descBullets.map((item, index) =>
            <li className={isDark ? "subTitle dark-mode-text" : "subTitle"} key={index}>{item}</li>) : null
    };

    return (
        <div className="experience-card">
            <div className="experience-banner" style={{backgroundColor: "rgb(28,63,81)"}}>
                <div className="experience-blurred_div"/>
                <div className="experience-div-company">
                    <h5 className="experience-text-company">{cardInfo.company}</h5>
                </div>

                <a href={cardInfo.website} target="_blank" rel="noreferrer"><img crossOrigin={"anonymous"} ref={imgRef}
                                                                         className="experience-roundedimg"
                                                                         src={cardInfo.companylogo}
                                                                         alt={cardInfo.company}/></a>
            </div>
            <div className="experience-text-details">
                <h5 className={"experience-text-role"}>{cardInfo.role}</h5>
                <h5 className={"experience-text-date"}>{cardInfo.date}</h5>
                <p className={"subTitle experience-text-desc"}>{cardInfo.desc}</p>
                <ul>
                    <GetDescBullets descBullets={cardInfo.descBullets} isDark={isDark}/>
                </ul>
            </div>
        </div>
    );
}
