import Header from "../case_studies/sections/Header/Header";
import React, {Component} from "react";
import Banner from "../case_studies/components/Banner/Banner"
import "./CaseStudiesPage.css"
import InnerHTMLSectionContainer from "shared_components/InnerHTMLSectionContainer/InnerHTMLSectionContainer";

const TextSections = (props) => {
    return (
        props.content.main_text.map(content => (
            <InnerHTMLSectionContainer key={content.title}
                                       title={content.title}
                                       id={content.title}>
                {content.content}
            </InnerHTMLSectionContainer>
        ))
    )
}

export class CaseStudiesPage extends Component {



    render() {
        return (
            <div>
                <Header/>
                <div className={"content"}>
                    <Banner title={this.props.content.banner_title}
                            desc={this.props.content.banner_desc}
                            colour={this.props.content.colour}
                            image={this.props.content.image}/>
                    <TextSections content={this.props.content}/>
                </div>
            </div>
        );
    }
}
