import React, {Component} from "react";
import "./Header.css"
import "./dropdown.css"
import {navBar} from '../../../../portfolio';


export default class Header extends Component {


    DropdownMenu(props) {
        if (props.dropdowns) {
            return (
                <div className={"dropdown-content"}>
                    {props.dropdowns.map((dropdown_data, index) => {
                        return <p key={index}><a href={dropdown_data.href}>{dropdown_data.text}</a></p>
                    })}
                </div>
            )
        } else {
            return null
        }
    }

    setupBarsMenu() {
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
            x.className += " responsive";
        } else {
            x.className = "topnav";
        }
    }

    render() {
        return (
            <header className="topnav" id={"myTopnav"}>
                <a href={"/"}><b className={"logo"}>{navBar.logo}</b></a>
                {navBar.buttons.map((button_data, index) => {
                    return (
                        <a className={button_data.dropdowns ? "dropdown" : undefined} href={button_data.href}
                           key={index}>{button_data.text}
                            <this.DropdownMenu dropdowns={button_data.dropdowns} key={index}/>
                        </a>
                    );
                })}
                <a href={"/"} className={"bars-menu"} onClick={this.setupBarsMenu}><i className="fa fa-bars"/></a>
            </header>
        );
    }
}
