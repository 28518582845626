import React, {Component} from "react";
import "./SectionContainer.css"

export default class SectionContainer extends Component {
    constructor(props) {
        super(props);
        this.props = props
    }

    render() {
        return (
            <div className="section-container" id={this.props.id}>
                <h1 className="section-heading">{this.props.title}</h1>
                {this.props.children}
            </div>
        );
    }
}
