import React, {Component} from "react";
import "./CaseStudies.css"
import {caseStudies} from '../../../../portfolio';
import CaseStudyCard from "../../components/CaseStudyCard/CaseStudyCard";
import SectionContainer from "../../../../shared_components/SectionContainer/SectionContainer";

export default class CaseStudies extends Component {


    render() {
        return (
            <SectionContainer title={"Case Studies"} id={"caseStudies"}>
                <div className="caseStudies-card-container">
                    {caseStudies.map((caseStudy, index) => (
                        <CaseStudyCard cardInfo={caseStudy} key={index}/>
                    ))}
                </div>
            </SectionContainer>
        );
    }
}
